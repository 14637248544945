import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyBjNV-8s22OXZeg1FgLvDIhGyz_0H7TlYI",
  authDomain: "firegram-tyz.firebaseapp.com",
  projectId: "firegram-tyz",
  storageBucket: "firegram-tyz.appspot.com",
  messagingSenderId: "638259912414",
  appId: "1:638259912414:web:b0f760e4088bfa31332c4f"
};

firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };