// src/comps/DarkModeToggle.js

import React, { useEffect, useState } from 'react';

const DarkModeToggle = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const body = document.querySelector('body');
    darkMode ? body.classList.add('dark-mode') : body.classList.remove('dark-mode');
  }, [darkMode]);

  return (
    <div className="dark-mode-toggle">
      <label className="switch">
        <input
          type="checkbox"
          className="checkbox"
          checked={darkMode}
          onChange={() => setDarkMode(!darkMode)}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
};

export default DarkModeToggle;
