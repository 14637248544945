// src/pages/FireGram.js

import React, { useState } from 'react';
import Title from '../comps/Title';
import UploadForm from '../comps/UploadForm';
import ImageGrid from '../comps/ImageGrid';
import Modal from '../comps/Modal';
import DarkModeToggle from '../comps/DarkModeToggle';

const FireGram = () => {
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <div className="App">
      <Title />
      <UploadForm />
      <ImageGrid setSelectedImg={setSelectedImg} />
      {selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
      
      <footer>
      <div className="dark-mode-toggle-container">
        <DarkModeToggle />
      </div>
        <div>
          <h2 className="footer-title">
            <span className="version">V2.1.1</span>
            <a href="#" className="footer-link">🩶</a>
          </h2>
        </div>
      </footer>
    </div>
  );
};

export default FireGram;
