// src/comps/Title.js

import React from 'react';
import Dropdown from './Dropdown';

const Title = () => {
  const dropdownItems = [
    { label: 'FireGram', path: '/#' },
    { label: 'Library', path: 'https://urp.mumtaz.my.id' },
    { label: 'Shorten URL', path: 'https://taz.my.id' }
  ];

  return (
    <div className="title">
      <Dropdown title="FireGram" items={dropdownItems} />
      <h2>We Pictures</h2>
      <p>Memories of us together</p>
    </div>
  );
};

export default Title;
